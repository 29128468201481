<template>
    <div class="body fixed mobile"> <!-- class: fixed, mobile -->
        <!-- <label for="locale">locale</label>
        <select v-model="locale">
            <option>en</option>
            <option>ja</option>
        </select>
        <p>message: {{ $t("hello") }}</p> -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header service">
            <div class="icon iconButton">
                <span class="fXL bold">市政互動</span>
            </div>
            <NotificationBell />
        </section>
        <!-- Main -->
        <section class="main service">
            <div class="top flexH width gapL">
                <a class="receipt flexV width between" :href="donateInvoiceURL">
                    <div class="img"><img src="@/assets/icon/s-receipt-w.svg"></div>
                    <span class="fL bold white">捐發票<br>換點數</span>
                    <i> 
                        <font-awesome-icon
                            icon="fa-solid fa-circle-arrow-right"
                            size="2x"
                        />
                    </i>
                </a>
                <a 
                    class="checkin flexV width between"
                    @click.prevent="$router.push('/service/on-site-activities')"
                >
                    <div class="img"><img src="@/assets/icon/s-checkin-w.svg"></div>
                    <span class="fL bold white">實體活動<br>報到</span>
                    <i>    
                        <font-awesome-icon
                            icon="fa-solid fa-circle-arrow-right"
                            size="2x"
                        />
                    </i>
                </a>
            </div>
            <span class="fL bold">市政調查</span>
            <a 
                class="middle flexH width gapM"
                @click.prevent="$router.push('/service/questions')"
            >
                <div class="img"><img src="@/assets/icon/s-qa-c.svg"></div>
                <div class="flexV width gapS">
                    <span class="fL bold">市政問答</span>
                    <span 
                        class="fS gray"
                        v-for="qnaCampaign in displayedQna"
                        :key="qnaCampaign.campaignId"
                    >{{ qnaCampaign.campaignName}}</span>
                </div>
                <div 
                    class="flexH rounded primaryBg"
                    v-if="numberOfQna"
                >
                    <span class="fS primary single">{{numberOfQna}}個進行中</span>
                </div>
            </a>
            <a 
                class="middle flexH width gapM"
                @click.prevent="$router.push('/service/survey/active')"
            >
                <div class="img"><img src="@/assets/icon/s-que-c.svg"></div>
                <div class="flexV width gapS">
                    <span class="fL bold">民意調查</span>
                    <span 
                        class="fS gray"
                        v-for="qreCampaign in displayedQuestionnaire"
                        :key="qreCampaign.campaignId"
                    >{{ qreCampaign.campaignName}}</span>
                </div>
                <div 
                    class="flexH rounded thirdBg"
                    v-if="numberOfQuestionnaire"
                >
                    <span class="fS third single">{{numberOfQuestionnaire}}個進行中</span>
                </div>
            </a>
            <a 
                class="middle flexH width gapM"
                @click.prevent="$router.push('/service/e-voting/active')"
            >
                <div class="img"><img src="@/assets/icon/s-vote-c.svg"></div>
                <div class="flexV width gapS">
                    <span class="fL bold">線上投票</span>
                    <span 
                        class="fS gray"
                        v-for="votingCampaign in displayedVote"
                        :key="votingCampaign.campaignId"
                    >{{ votingCampaign.campaignName}}</span>
                </div>
                <div 
                    class="flexH rounded primaryBg"
                    v-if="numberOfVote"
                >
                    <span class="fS primary single">{{numberOfVote}}個進行中</span>
                </div>
            </a>
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NotificationBell from '@/components/NotificationBell.vue';

export default {
    name: "Service",
    components: {
        NotificationBell,
    },
    data() {
        // this.$i18n.locale = "en";
        return { 
            // locale: "en",
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            donateInvoiceURL: process.env.VUE_APP_DONATE_INVOICE_URL,
            campaigns: [],
        };
    },
    mounted() {
        this.getCampaigns();
    },
    computed:{
        ...mapState(["user"]),
        qnaCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'qna');
        },
        voteCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'vote');
        },
        questionnaireCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'questionnaire');
        },
        numberOfQna() {
          return this.qnaCampaigns.length;  
        },
        numberOfVote() {
          return this.voteCampaigns.length;  
        },
        numberOfQuestionnaire() {
          return this.questionnaireCampaigns.length;  
        },
        displayedQna() {
            return this.qnaCampaigns.slice(0, 3);
        },
        displayedVote() {
            return this.voteCampaigns.slice(0, 3);
        },
        displayedQuestionnaire() {
            return this.questionnaireCampaigns.slice(0, 3);
        },
    },
    watch: {
        locale(val) {
            this.$i18n.locale = val;
        }
    },
    methods: {
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true&publisherType=municipal`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    .then((res) => {
                        this.campaigns = res.data.campaigns.filter(
                            ({ campaignVisibility }) => campaignVisibility == 'public'
                        );
                        this.sortCampaigns();
                    })
            );
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns);
        },    
    }
};
</script>

<i18n>
{
  "en": {
    "hello": "hello world!666"
  },
  "ja": {
    "hello": "こんにちは、世界！666"
  }
}
</i18n>